import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "../Reviews/sendreview.css"

const SendReview = () => {

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_06vggu9', 'template_r8kg1vi', form.current, 'YUV82ir3iaHkzxdUL')
      .then((result) => {
          console.log(result.text);
          e.target.reset()
          alert("Your review has been sent successfully, Thank you!")
      }, (error) => {
          console.log(error.text);
      });

    }

  return (
    <div>
        <div className='header-review'>
            <img className='review-header-img' src="https://efglobaltravels.com/wp-content/uploads/2022/07/EF-GLOBAL-TRAVELS-LOGO-01.png" alt="" />
        </div>
        <div className='reviewstar-imgdiv'>
        <img  src="https://cdn-icons-png.flaticon.com/128/10840/10840698.png" alt="" />
        </div>
<form className='review-form' ref={form} onSubmit={sendEmail}>
        <h1 className='review-header'>Your review is valueable to us</h1>
      <label>Name</label>
      <input required type="text" name="user_name" />
      <label>Email</label>
      <input required type="email" name="user_email" />
      <label>Message</label>
      <textarea required rows={25}name="message" />
      <input className='reviewsubmit-btn' type="submit" value="Send" />
    </form>
    </div>
  )
}

export default SendReview